import { api } from 'Plugins/potagerApiClient';
import { storeCommit } from 'Services/storeServices';
import { SET_IS_404 } from 'Stores/types/appMutationsTypes';

// Check on 404 if there is a custom page
export default function (to, from, next) {
  const on404 = (endpoint) => {
    console.info('Custom page not found', endpoint);
    next();
    setTimeout(() => storeCommit(`app/${SET_IS_404}`, true));
  };

  // if /custompage is in the path, it's the route alias that duplicate the native one, so we don't check
  if (to.name === 'not-found' || (to.name === 'custom-pages' && !to.path.includes('custompage'))) {
    const endpoint = (to.params.url || to.href).replace(/\/$/, '');

    // If we are on the same page, we don't need to check
    // needed for potagerRouting (on opening panels)
    if (to.path === from.path) {
      next();
    } else {
      api.common.checkCustomPage(endpoint)
        .then((response) => {
          if (!response.data.success) {
            on404(endpoint);
          } else {
            next();
          }
        })
        .catch(() => {
          on404(endpoint);
        });
    }
  } else {
    next();
  }
}
