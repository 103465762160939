<template>
  <div class="not-found-page">
    <div class="not-found-page__content">
      <div class="not-found-page__content__wrapper">
        <potager-picture
          class="not-found-page__content__img"
          src="erreur-404-kiwi"
          title="404" />
      </div>

      <div class="not-found-page__content__details">
        <p>
          Redirection vers la page d'accueil dans {{ timing }} seconde{{ timing > 1 ? 's' : '' }}
        </p>

        <potager-button
          class="not-found-page__content__btn mb-10"
          @onClick="redirect">
          Aller à l'accueil
        </potager-button>
      </div>
    </div>
  </div>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';
import MetaInfosService from 'Classes/services/MetaInfoService';

let timer;

export default {
  components: {
    PotagerButton,
    PotagerPicture,
  },

  data: () => ({
    timing: 5,
  }),

  mounted() {
    timer = setInterval(() => {
      this.timing -= 1;

      if (this.timing === 0) {
        this.redirect();
        clearInterval(timer);
      }
    }, 1000);
  },

  computed: {
    isFlyingPageOpened() {
      return !!this.$potagerRoute.meta?.isFlying || !!this.$potagerRoute.meta?.isPanel;
    },
  },

  methods: {
    redirect() {
      if (this.isFlyingPageOpened) {
        this.$events.emit('panel:close');
        this.$events.emit('flyingPage:close');
      } else {
        this.$router.push({ name: 'home' });
      }
    },
  },

  unmounted() {
    clearInterval(timer);
  },

  beforeRouteEnter(to, from, next) {
    next();
  },

  head: MetaInfosService.generate({
    title: 'Page introuvable',
    meta: [
      {
        name: 'prerender-status-code',
        content: '404'
      },
    ],
  }),
};
</script>

<style lang="scss">
.not-found-page {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
    table-layout: fixed;

    &__wrapper {
      width: 100%;
    }

    &__img {
      max-width: $container-width--xsmall;
      position: relative;
      margin: 0 auto;
    }

    &__details {
      text-align: center;
      @apply text-xs.5;
      font-weight: $weightSemibold;
      line-height: $baseLh;
      margin-top: -4rem;
      z-index: 1;
    }

    &__timer {
      @apply text-lg;
      line-height: $baseLh;
      margin-top: .5rem;
    }

    &__btn {
      margin-top: 1rem;
    }
  }
}
</style>
