import { cleanWordpress } from 'Classes/utils/WordpressUtils';

export const RECEIPTS_CONTENT_TYPE = 'recette';
export const GROWER_CONTENT_TYPE = 'producteurs';
export const REPORTS_CONTENT_TYPE = 'reports';
export const PRODUCTS_CONTENT_TYPE = 'produits';

import { api } from 'Plugins/potagerApiClient';

import { SET_IS_404 } from 'Stores/types/appMutationsTypes';

export default {
  data: () => ({
    content: {},
  }),

  computed: {
    id() {
      return parseInt(this.$route.params.id, 10);
    },
  },

  watch: {
    id: {
      handler(val) {
        if (val && (!this.content || (this.content.id !== this.id))) {
          this.fetchData();
        }
      },
      immediate: true,
    },
  },

  methods: {
    fetchData(contentType, contentId) {
      this.$wait.start('FETCH_CONTENT_DATA');
      this.getCallApi(contentId || this.id, contentType)
        .then(({ data }) => {
          this.content = data;
        })
        .catch(() => this.$store.commit(`app/${SET_IS_404}`, true))
        .finally(() => this.$wait.end('FETCH_CONTENT_DATA'));
    },
    getCallApi(id, contentType) {
      return new Promise((resolve, reject) => {
        let apiCall;
        switch (contentType || this.$route.name) {
          case RECEIPTS_CONTENT_TYPE:
            apiCall = api.recipe.findOne(id);
            break;
          case GROWER_CONTENT_TYPE:
            apiCall = api.supplier.findOneById(id);
            break;
          case REPORTS_CONTENT_TYPE:
            apiCall = api.report.findOne(id);
            break;
          default:
            apiCall = api.supplierProducts.findOneById(id);
            break;
        }

        return apiCall
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
    cleanWP(str) {
      return cleanWordpress(str);
    },
  },
};
