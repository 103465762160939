<template>
  <flying-page>
    <div class="recipe-detail">
      <potager-picture
        :src="recipe.image"
        :title="recipe.title"
        background
        class="recipe-detail__poster"
        placeholder>
        <favori
          :id="id"
          class="recipe-detail__fav" />
      </potager-picture>

      <div class="recipe-detail__content">
        <section class="recipe-detail__section">
          <span
            v-if="recipe.loading"
            class="recipe-detail__subtitle h-8 w-1/3 skeleton" />
          <h1
            v-else
            class="recipe-detail__title">
            {{ recipe.title }}
          </h1>

          <preparation
            :recipe="recipe"
            class="recipe-detail__preparation" />

          <div
            v-if="recipe.loading"
            class="recipe-detail__description h-8 w-2/3 skeleton" />
          <div
            class="recipe-detail__description"
            v-html="description" />
        </section>

        <section
          v-if="recipe.loading"
          class="recipe-detail__section print:hidden">
          <div class="recipe-detail__subtitle h-8 w-1/3 skeleton" />

          <potager-row
            v-for="i in [1, 2]"
            :key="`skeleton-${i}`">
            <potager-col
              col="12">
              <card-product landscape />
            </potager-col>
          </potager-row>
        </section>

        <section
          v-if="boxesRelated?.length || recipe.loading"
          class="recipe-detail__section print:hidden">
          <div
            v-if="recipe.loading"
            class="recipe-detail__subtitle h-8 w-1/3 skeleton" />
          <h2
            v-else
            class="recipe-detail__subtitle">
            Les produits qui vous aideront à cuisiner cette recette
          </h2>

          <potager-row>
            <potager-col
              v-for="(box, index) in (boxesRelated)"
              :key="`box-${index}`"
              col="12">
              <card-product
                :box="box"
                landscape />
            </potager-col>
          </potager-row>
        </section>

        <section class="recipe-detail__section">
          <div
            v-if="recipe.loading"
            class="recipe-detail__subtitle h-8 w-1/3 skeleton" />
          <h2
            v-else
            class="recipe-detail__subtitle">
            Les ingrédients
          </h2>

          <ingredient-list
            :ingredients="recipe.ingredients_v2 || [1, 2, 3]"
            class="recipe-detail__ingredient-list" />
        </section>

        <section class="recipe-detail__section">
          <div
            v-if="recipe.loading"
            class="recipe-detail__subtitle h-8 w-1/3 skeleton" />
          <h2
            v-else
            class="recipe-detail__subtitle">
            Déroulé de la recette
          </h2>

          <recipe-steps
            :recipe="recipe"
            class="recipe-detail__recipe-steps" />
        </section>

        <section class="recipe-detail__section recipe-detail__section--grey print:hidden">
          <social-media
            :facebook="facebook"
            :mail="mail"
            :pinterest="pinterest"
            :twitter="twitter" />
        </section>

        <upselling-slider flying />

        <associated-content-slider
          ref="reports"
          :items="recipesRelated"
          subtitle="Savourez nos recettes qui acompagnent les paniers vendus actuellement"
          title="Nos autres recettes du moment" />

        <section
          v-if="!isSubscriptionActive"
          class="recipe-detail__section print:hidden">
          <push-subscription condensed />
        </section>
      </div>

      <micro-datas
        :content="recipe"
        context="recipe" />
    </div>
  </flying-page>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_IS_404 } from 'Stores/types/appMutationsTypes';

import FlyingPageMixin from 'Mixins/FlyingPageMixin';

import PotagerPicture from 'UI/PotagerPicture';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';

import Preparation from 'Components/recette/Preparation';
import IngredientList from 'Components/lists/IngredientList';
import RecipeSteps from 'Components/recipe/RecipeSteps';
import Favori from 'Components/recette/Favori';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { getResolvedUrl, calcSlug } from 'Classes/utils/RouteUtils';
import { isEmpty } from 'Classes/utils/BasicUtils';
import { cleanWordpress, stripHtml } from 'Classes/utils/WordpressUtils';

import uniqBy from 'lodash/uniqBy';

export default {

  mixins: [
    FlyingPageMixin,
  ],

  components: {
    PotagerRow,
    PotagerCol,
    PotagerPicture,
    Preparation,
    IngredientList,
    RecipeSteps,
    Favori,
  },

  data() {
    return {
      recipe: {
        loading: true,
        steps: [
          1,
          2,
          3
        ]
      },
    };
  },

  computed: {
    ...mapGetters('dashboard', [
      'isLoaded',
      'getMiniBoxes',
    ]),
    id() {
      return parseInt(this.$potagerRoute.params.id, 10);
    },
    isNewRecipeUrl() {
      return this.$potagerRoute.name !== 'recette-detail-item-old';
    },
    description() {
      return cleanWordpress(this.recipe.description);
    },
    boxesRelated() {
      if (this.recipe.loading) {
        return [1].map(() => ({ loading: true }));
      }

      return this.getMiniBoxes
        .filter((e) => !e.loading && e.recipes
          .find((r) => r.id === this.id) && !e.isTypeFormule && !e.isSoldOut);
    },
    recipesRelated() {
      let recipes = [];
      if (this.getMiniBoxes) {
        this.getMiniBoxes.forEach((p) => {
          if (!p.loading) {
            p.recipes.forEach((r) => {
              recipes.push(r);
            });
          }
        });
        recipes = recipes.filter((r) => r.id !== this.id);
        recipes = uniqBy(recipes, 'id');
      }
      return recipes;
    },
    mail() {
      return {
        id: this.recipe.id,
        subject: `Viens découvrir la recette : ${this.recipe.title}`,
        body: `Hello,\nViens découvrir cette recette sur Potager City :\n\n${this.canonical}`,
        url: this.canonical,
      };
    },
    twitter() {
      return {
        url: this.canonical,
        text: `${this.recipe.title} - Recette de Potager City`,
      };
    },
    facebook() {
      return { url: this.canonical };
    },
    pinterest() {
      return {
        url: this.canonical,
        image: this.recipe.image ? this.recipe.image.url : null,
        description: `${this.recipe.title} - Recette de Potager City`,
      };
    },
    canonical() {
      if (this.recipe.id) {
        return getResolvedUrl('recette', {
          slug: calcSlug(this.recipe.title),
          id: this.recipe.id,
        });
      }
      return getResolvedUrl('recettes-list');
    },
  },

  watch: {
    id: {
      handler() {
        this.getRecipe();
      },
      immediate: true,
    },
  },

  methods: {
    getRecipe() {
      this.$api.recipe.findOne(this.id)
        .then(({ data }) => {
          this.recipe = data;
        })
        .catch(() => {
          this.$store.commit(`app/${SET_IS_404}`, true);
        });
    },
  },

  mounted() {
    if (!this.isLoaded) this.fetchDashboard();
  },

  head() {
    let metaInfo = {};

    if (this.recipe.id) {
      metaInfo = {
        title: `Recette ${this.recipe.title}`,
        description: `Découvrez la recette ${this.recipe.title}. ${stripHtml(this.recipe.description)}`,
        image: this.recipe.image ? this.recipe.image.url : null,
        link: [
          {
            rel: 'canonical',
            href: this.canonical,
          }
        ],
      };

      if (!this.isNewRecipeUrl) {
        const newFlyingPageUrl = getResolvedUrl('recette-detail-item', {
          slug: calcSlug(this.recipe.title),
          id: this.recipe.id,
        });
        metaInfo.meta = [
          {
            name: 'prerender-status-code',
            content: '301'
          },
          {
            name: 'prerender-header',
            content: `Location: ${newFlyingPageUrl}`
          },
        ];
      }
    }

    if (!isEmpty(metaInfo)) {
      return MetaInfosService.generate(metaInfo);
    }

    return metaInfo;
  },

};
</script>
